@import 'src/theme.scss';

.container {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 2px solid $border;
  border-radius: 4px;
  overflow: hidden;

  &.focus {
    border-color: $primary;
  }

  &.disabled {
    background-color: $disabled;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      box-shadow: 0 0 0 30px $disabled inset !important;
    }
  }

  input {
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 50000s;
      -webkit-text-fill-color: white !important;
    }
  }
}

.error {
  margin-top: 3px;
  font-size: 0.75rem;
  color: #dc3545;
}
