@import 'src/theme.scss';

.container {
  max-width: 420px;
  padding: 12px 28px 28px;
  border-radius: 8px;
  background-color: rgba($color: #ffffff, $alpha: 0.8);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  > .title {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #000000;
    font-weight: 700;

    svg {
      margin-right: 10px;
      filter: invert(0);
    }
  }

  > .loginForm {
    display: flex;
    flex-direction: column;
    color: #000000;

    .inputField {
      label,
      input {
        color: #000000;
      }
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active{
    -webkit-text-fill-color: #000000 !important;
  }
}
