.container {
  height: 100vh;
  background-image: url('../../../../assets/images/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
}
