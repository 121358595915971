.container {
  overflow: auto;

  > table {
    width: 100%;

    th {
      background: #f7f8fc;
      border: 1px solid #dfe0eb;
      color: #333333;
      font-weight: 700;
      padding: 12px;
      border-radius: 4px;
    }

    tr {
      background: #fefefe;

      &:nth-child(even) {
        background-color: #fdf5f5;
      }

      td {
        padding: 10px;
        border: 1px solid #dfe0eb;

        border-radius: 4px;
      }
    }

    .empty {
      text-align: center;
    }
  }

  > nav > ul {
    display: flex;
    justify-content: center;
  }
}
