.panelContainer {
  border: 1px solid #dfe0eb;

  > .panelHeader {
    background-color: #f7f8fc;
    padding: 16px 24px;
    border-bottom: 1px solid #dfe0eb;
  }

  > .panelContent {
    padding: 16px 24px;
  }
}
