.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  > .selectBtn {
    margin-left: 16px;
  }

  > .exportGroup {
    margin-left: auto;
    display: flex;
    align-items: center;
    > .exportBtn {
      margin-left: 16px;
    }
  }

  > .customer {
    margin-right: 16px;
  }
}

.verifyDates {
  display: flex;
  margin-bottom: 20px;
  overflow-y: auto;

  > button {
    background-color: #ff7b72b3;
    border: 1px solid #ff7b72b3;
    min-width: 100px;

    &:not(:first-child) {
      margin-left: 14px;
    }

    &.active,
    &:hover {
      background-color: #ff7b72;
    }
  }
}

.error {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}
