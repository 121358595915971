@import 'src/theme.scss';

.reportStatus {
  padding: 6px 8px;
  border-radius: 6px;
  color: #333333;
  font-weight: 500;
  text-align: center;
  background-color: $normal;

  &.verify {
    background-color: $verify;
  }
}

.adjust {
  color: $adjust;
}

.box {
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.h56 {
  height: 56px;
}

.noWrap {
  white-space: nowrap;
}

.setParameter {
  background-color: $parameter;
}

.allTank {
  background-color: $allTank;
}

.usable {
  background-color: $usable;
}

.available {
  background-color: $available;
}
