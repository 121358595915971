.container {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;

  .inputGroup {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
