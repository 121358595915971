@import 'src/theme.scss';

.form {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;

  .w50 {
    width: 50%;

    &:nth-child(odd) {
      padding-right: 10px;
    }

    &:nth-child(even) {
      padding-left: 10px;
    }
  }

  .inputGroup {
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    > strong {
      margin-right: 8px;
    }

    .inputItem {
      flex: 1;
    }
  }

  .tankList {
    width: 47%;
    padding: 10px;
    border: 1px solid $border;
    margin-bottom: 8px;

    .header {
      padding: 10px;
      background-color: #f7f8fc;
      border: 1px solid $border;
    }

    .tankItem {
      padding: 0 10px;
      border: 1px solid $border;
      margin-top: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        svg {
          color: $danger;
        }
      }
    }
  }

  > .arrow {
    margin: auto;
  }

  > .btnGroup {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
