@import 'src/theme.scss';

.container {
  display: flex;
  color: #333333;

  > label:not(:first-child) {
    margin-left: 16px;
  }
}

.radioContainer {
  position: relative;
  min-height: 20px;
  display: flex;
  align-items: center;
  padding-left: 28px;

  > input {
    position: absolute;
    opacity: 0;
  }

  > .circle1 {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #c4c4c4;
    border-radius: 50%;
  }

  > .circle2 {
    position: absolute;
    top: 0;
    left: 0;
    height: 8px;
    width: 8px;
    background-color: #dfe0eb;
    border-radius: 50%;
    top: 10px;
    left: 10px;
    transform: translate(-50%, -50%);
  }

  > .circle1:after {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
  }

  > input:checked ~ .circle1,
  input:checked ~ .circle2 {
    background-color: $primary;
  }
}
