.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  > .inputGroup {
    display: flex;
    align-items: center;

    > .productOptions,
    > .tankOptions,
    > .activityOptions {
      width: 200px;
    }

    > .monthOptions,
    > .yearOptions {
      width: 100px;
    }

    > div:not(:first-child) {
      margin-left: 10px;
    }

    > span:not(:first-child) {
      margin-left: 20px;
    }
  }

  button:not(:first-child) {
    margin-left: 10px;
  }
}
