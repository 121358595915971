@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import 'src/theme.scss';

html {
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: 'Roboto', sans-serif;
}

.error-message {
  color: #dc3545;
  margin-top: 5px;
}

.mt {
  margin-top: 16px !important;
}

.mb {
  margin-bottom: 16px !important;
}

.ml {
  margin-left: 16px !important;
}

.text-right {
  text-align: right;
}

a {
  text-decoration: none;
}

.text-center {
  text-align: center;
}

.label {
  color: #333333;
  margin-bottom: 8px;
}

.conflict {
  .input-group {
    border: 2px solid $danger;
  }
}

// /* width */
// ::-webkit-scrollbar {
//   width: 10px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   background-color: #ffffff;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #c4c4c4;
//   border-radius: 4px;
// }
