.formWrapper {
  position: relative;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #fff;
    padding: 12px 0;

    .select {
      width: 200px;
    }
  }
}
