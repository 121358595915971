@import 'src/theme.scss';

.container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;

  .retrieveSection {
    grid-column: 1 / 4;
  }

  .calculationDetailHeader,
  .noteHeader {
    grid-column: 1 / 7;
    padding: 6px 10px;
    background-color: #f7f8fc;
    border: 1px solid $border;
    border-radius: 4px;
    font-weight: 500;
  }

  .noteDetail {
    grid-column: 1 / 7;
    padding: 10px;
    font-family: 'Roboto';
  }

  .calculationDetailLabel {
    grid-column: 1 / 4;

    &.calculate {
      color: $primary;
      font-size: 16px;
    }
  }
}
