@import 'src/theme.scss';

.checkList {
  margin: 16px 0;

  > .item {
    display: grid;
    gap: 10px;
    grid-template-columns: 2fr 4fr;
    align-items: center;

    &:not(:first-child) {
      margin-top: 6px;
    }

    > .name {
      text-align: right;
    }

    > .status {
      display: flex;
      align-items: center;

      label {
        color: $primary;
      }

      svg {
        margin-left: 10px;
        color: $checked;
      }
    }
  }
}

.disabledBtn {
  background-color: #ededed;
}

.disabled {
  a {
    pointer-events: none;
  }
}

.actionGroup {
  display: flex;
  justify-content: space-between;
}
