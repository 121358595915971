@import 'src/theme.scss';

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  > .manualDip {
    .manualDipContent {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 6px;

      > .manualDipLabel {
        text-align: right;
        margin: auto 0;
      }

      > .manualDipHeader {
        padding: 10px;
        text-align: center;
        font-weight: 500;
        background-color: #f7f8fc;
        border: 1px solid $border;
        border-radius: 4px;

        &.disabled {
          background-color: $disabled;
          border-color: $disabled;
        }
      }

      > .manualDipAction {
        text-align: center;
      }
    }
  }

  > .atgReport {
    grid-column: 1 / 3;

    .atgReportContent {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 16px;

      > .atgReportInformation {
        display: grid;
        gap: 6px;
        grid-template-columns: 1fr 2fr;
        align-items: center;

        > label {
          text-align: right;
        }

        > .retrieveBtn {
          grid-column: 1 / 3;
        }
      }

      .atgReportOperation {
        grid-column: 2 / 4;

        .atgReportOperationContent {
          display: grid;
          gap: 6px;
          grid-template-columns: 1fr 6fr;

          span {
            padding: 6px 10px;
            border-radius: 4px;
            display: inline-block;
            width: 65px;
            text-align: center;

            &.hh,
            &.ll {
              background-color: #ff7b72;
            }

            &.ph,
            &.pl {
              background-color: #fff501;
            }
          }

          > label {
            text-align: right;
          }
        }
      }

      .atgReportActionForm {
        display: grid;
        grid-template-columns: 1fr;
        gap: 6px;
        align-items: center;
        text-align: center;
      }

      form {
        grid-column: 1 / 7;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 16px;
      }

      .atgReportCalculationDetailHeader {
        grid-column: 1 / 7;
        padding: 6px 10px;
        background-color: #f7f8fc;
        border: 1px solid $border;
        border-radius: 4px;
        font-weight: 500;
      }

      .atgReportCalculationDetailLabel {
        grid-column: 1 / 4;

        &.calculate {
          color: $primary;
          font-size: 16px;
        }
      }

      .atgReportCalculationDetailAction {
        display: flex;
        flex-direction: column;
        gap: 6px;
        align-items: center;
      }

      .InputCalculate {
        input {
          font-size: 16px;
        }
      }

      .atgReportNoteHeader {
        grid-column: 1 / 7;
        padding: 6px 10px;
        background-color: #f7f8fc;
        border: 1px solid $border;
        border-radius: 4px;
        font-weight: 500;
      }

      .atgReportNoteDetail {
        grid-column: 1 / 7;
        padding: 10px;
        font-family: 'Roboto';
      }
    }
  }
}

.inputGroup {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  align-items: center;

  &:not(:first-child) {
    margin-top: 6px;
  }

  > label {
    text-align: right;
  }

  > .purityInput {
    grid-column: 2 / 4;
  }

  > .prorateLabel {
    grid-column: 1 / 3;
  }

  > .vesselInput {
    grid-column: 1 / 4;
  }

  > .goalSeekInput {
    grid-column: 2 / 4;
  }
}

.calBtn {
  margin-top: 6px;
  text-align: right;
}

.disabled {
  background-color: $disabled;
}

.vesselLoadForm {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 6px;

  .difference {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 6px;
  }
}
