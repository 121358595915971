@import 'src/theme.scss';

.submitGroup {
  text-align: right;
  margin-bottom: 16px;
}

.confirmMessage {
  font-size: 16px;
  color: #333333;

  span {
    color: $primary;
  }
}
