.container {
  overflow: auto;
  max-height: 600px;

  .dailyReport {
    tr {
      &:nth-child(1) > th:nth-child(1),
      &:nth-child(1) > th:nth-child(2),
      &:nth-child(1) > th:nth-child(3),
      &:nth-child(2) > th:nth-child(1),
      &:nth-child(2) > th:nth-child(2),
      &:nth-child(2) > th:nth-child(3),
      &:nth-child(3) > th:nth-child(1),
      &:nth-child(3) > th:nth-child(2),
      &:nth-child(3) > th:nth-child(3),
      &:nth-child(3) > th:nth-child(4),
      > td:nth-child(1),
      > td:nth-child(2),
      > td:nth-child(3),
      > td:nth-child(4) {
        position: sticky;
      }

      &:nth-child(1) > th:nth-child(1),
      &:nth-child(2) > th:nth-child(1),
      &:nth-child(3) > th:nth-child(1),
      > td:nth-child(1) {
        left: 0;
      }

      &:nth-child(3) > th:nth-child(2),
      > td:nth-child(2) {
        left: 47.7px;
      }

      &:nth-child(1) > th:nth-child(2),
      &:nth-child(2) > th:nth-child(2),
      &:nth-child(3) > th:nth-child(3),
      > td:nth-child(3) {
        left: 162.28px;
      }

      &:nth-child(1) > th:nth-child(3),
      &:nth-child(2) > th:nth-child(3),
      &:nth-child(3) > th:nth-child(4),
      > td:nth-child(4) {
        left: 241.91px;
      }
    }
  }

  > table {
    width: 100%;

    thead {
      position: sticky;
      top: 0;
      z-index: 10;
    }

    th {
      background: #f7f8fc;
      border: 1px solid #dfe0eb;
      color: #333333;
      font-weight: 700;
      border-radius: 4px;
      white-space: nowrap;
      padding: 0;
      min-width: 45px;
    }

    tr {
      &:nth-child(even) > td {
        background-color: #fdf5f5;
      }

      td {
        background: #fefefe;
        padding: 10px;
        border: 1px solid #dfe0eb;
        border-radius: 4px;
      }
    }

    .empty {
      text-align: center;
    }
  }

  > nav > ul {
    display: flex;
    justify-content: center;
  }
}
