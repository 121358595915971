@import 'src/theme.scss';

.textarea {
  padding: 10px;
  border: 1px solid $border;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;

  &:focus {
    outline-color: $primary;
  }
}

.error {
  margin-top: 3px;
  font-size: 0.75rem;
  color: #dc3545;
}
