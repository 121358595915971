$primary: #176D88;
$primary90: #05445Ee6;
$primary80: #176D88cc;
$secondary: #fefefe;
$text: #fefefe;
$normal: #4caf50;
$verify: #fff501;
$border: #dfe0eb;
$adjust: #db3d32;
$danger: #ff0000;
$checked: #4caf50;
$disabled: #eeebeb;
$warning: #ff9800;

$parameter: #fcecdd;
$allTank: #ffc288;
$usable: #fea82f;
$available: #ff6701;

$caculation: #dd4e44;
$button: #FFCE40;
$textbutton: #000000;
$headerCal: #B1D4E0;