@import 'src/theme.scss';

.searchForm {
  display: flex;
  align-items: center;

  > .select {
    min-width: 120px;
  }

  > strong {
    margin-right: 10px;
  }

  > strong:not(:first-child) {
    margin-left: 16px;
  }

  > button {
    margin-left: auto;
  }
}

.rejectSearch {
  margin-top: 30px;
}

.notStart,
.waitSubmitWork,
.onProcess,
.verifying,
.complete,
.approve,
.reject {
  text-align: center;
  border-radius: 4px;
  padding: 6px 10px;
}

.notStart,
.waitSubmitWork {
  background-color: #c4c4c4;
}

.onProcess,
.verifying {
  background-color: #faff04;
}

.complete,
.approve {
  background-color: #3bfe43;
}

.reject {
  background-color: #ff7b72b3;
}

.modifyWork,
.revise {
  text-align: center;
  > button {
    color: #ff9900;
  }
}

.rejectWork {
  text-align: center;
  > button {
    color: #ff1100;
  }
}

.recoverWork {
  text-align: center;
  > button {
    color: #4caf50;
  }
}

.workModal {
  .title {
    background-color: $primary;
    color: $text;
  }
}

.confirmMessage {
  font-size: 16px;
  color: #333333;

  span {
    color: $primary;
  }
}
