.submitGroup {
  text-align: right;
  margin-bottom: 16px;
}

.header {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 16px;
}

.inputGroup {
  display: flex;
  margin-bottom: 16px;

  > div {
    flex: 1;

    &:not(:first-child) {
      margin-left: 16px;
    }
  }
}

.note {
  width: 100%;
}
