@import 'src/theme.scss';

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px 10px;
}

.boxContent {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 6px 10px;

  > div {
    display: flex;
    align-items: center;

    &.header {
      justify-content: center;
    }

    input {
      width: 100%;
    }
  }
}

.meterReportContainer {
  grid-column: 1 / 3;

  .meterReportContent {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 16px;

    > .meterReportInformation {
      grid-column: 1 / 4;
      display: grid;

      button {
        margin: auto auto 0 0;
      }
    }

    .meterReportActionForm {
      display: grid;
      grid-template-columns: 1fr;
      gap: 6px;
      align-items: center;
      text-align: center;
    }

    .meterReportCalculationHeader {
      grid-column: 1 / 7;
      padding: 6px 10px;
      background-color: #f7f8fc;
      border: 1px solid $border;
      border-radius: 4px;
      font-weight: 500;
    }

    .meterReportCalculationLabel {
      grid-column: 1 / 4;

      &.calculate {
        color: $primary;
      }
    }
  }
}

.disabledBtn {
  background-color: #ededed;
}