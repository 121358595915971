@import 'src/theme.scss';

.footer {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;

  > div > button:last-child {
    margin-left: 6px;
  }
}

.header {
  padding: 10px 6px;
  background-color: #f7f8fc;
  color: #333333;
  margin: 8px 0;
  font-size: 16px;
}

.infomation {
  font-size: 16px;
  color: #333333;

  > span:not(:first-child) {
    margin-left: 20px;
  }
}

.calculate {
  color: $primary;
}
