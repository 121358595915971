@import 'src/theme.scss';

.container {
  height: 100vh;
  overflow-x: hidden;
  > .appBar {
    background-color: $primary90;
    font-size: 20px;
    font-weight: 700;
    color:$text;
    .userInfo {
      margin-left: auto;
    }

    .menu {
      margin: 0;
      padding: 0 24px;
      background-color: $primary;
      > li {
        padding: 0 8px;
        display: inline-block;
        > button {
          color: $text;
        }
      }
    }
  }
}

.paper {
  margin-top: 4px;
  .subMenu {
    min-width: 150px;
    background-color: $primary80;
    color: $text;

    > .subMenuItem {
      padding: 10px 16px;

      &:hover {
        background-color: $primary90;
        cursor: pointer;
      }
    }
  }
}

.alert {
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 6px;
  max-width: 400px;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  svg {
    margin-right: 10px;
  }

  &.success {
    background-color: #4caf50;
  }

  &.error {
    background-color: #f57c00;
  }

  &.info {
    background-color: #2196f3;
  }
}
