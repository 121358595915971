.checkListGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.checkList {
  background-color: #c4c4c4;

  &.active {
    background-color: #fff;
  }
}
