@import 'src/theme.scss';

.box {
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.h56 {
  height: 56px;
}

.noWrap {
  white-space: nowrap;
}

.setParameter {
  background-color: $parameter;
}

.allTank {
  background-color: $allTank;
}

.usable {
  background-color: $usable;
}

.available {
  background-color: $available;
}
