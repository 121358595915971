@import 'src/theme.scss';

.container {
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  margin: auto;
  text-align: center;

  .icon {
    font-size: 72px;
    margin-bottom: 10px;
  }
}
