.infomation {
  display: flex;
  flex-wrap: wrap;

  .label {
    width: 20%;
    text-align: right;
    padding-right: 10px;
  }

  .workRequestNo {
    width: 80%;
  }

  .text {
    width: 30%;
  }
}
