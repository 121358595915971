@import 'src/theme.scss';

.searchForm {
  display: flex;
  align-items: center;

  > .select {
    min-width: 120px;
  }

  > strong {
    margin-right: 10px;
  }

  > strong:not(:first-child) {
    margin-left: 16px;
  }

  > button {
    margin-left: auto;
  }
}

.modifyWork {
  text-align: center;
  > button {
    color: #ff9900;
  }
}

.workModal {
  .title {
    background-color: $primary;
    color: $text;
  }
}
