@import 'src/theme.scss';

.group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.status {
  padding: 6px 10px;
  border-radius: 6px;
  background-color: #3333334d;
  color: #fff;
  width: 67px;
  text-align: center;

  &.active {
    background-color: #4caf50;
  }
}

.table {
  border-collapse: collapse;
  border-spacing: 0;

  > thead th {
    text-align: left;
    &:first-child {
      border-right: none;
    }

    &:last-child {
      border-left: none;
    }

    &:not(:first-child):not(:last-child) {
      border-right: none;
      border-left: none;
    }
  }

  > tbody td {
    background-color: #fff;
    border: none;
    border-bottom: 1px solid $border;
  }
}
