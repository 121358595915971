@import 'src/theme.scss';

.actionWrapper {
  text-align: center;
  .approve {
    span {
      color: $normal;
    }
  }

  .reject {
    span {
      color: $danger;
    }
  }
}

.confirmMessage {
  span {
    color: $primary;
  }
}

.textCenter {
  text-align: center;
}
