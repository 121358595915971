@import 'src/theme.scss';

.container {
  border: 1px solid $border;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  > .title {
    background-color: $headerCal;
    border-bottom: 1px solid $border;
    padding: 10px 16px;
    font-size: 16px;
    font-weight: 500;
  }

  > .content {
    padding: 10px 16px;
    flex: 1;
  }
}
