.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  > .selectBtn {
    margin-left: 16px;
  }

  > .exportBtn {
    margin-left: auto;
  }

  > .select {
    max-width: 120px;
    margin: 0 16px 0 10px;
  }

  > .fromDate {
    margin: 0 10px;
  }

  > .toDate {
    margin-left: 10px;
  }

  > .product {
    min-width: 150px;
    > strong {
      margin-right: 10px;
    }

    margin-right: 16px;
  }

  strong {
    color: #333333;
  }
}
